import React, { useState, useMemo } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import { useHistory, Switch, Route, useLocation } from 'react-router-dom';
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { Button } from "../../common/Button";
import {
    HeaderSection,
    LogoContainer,
    Burger,
    NotHidden,
    Menu,
    CustomNavLinkSmall,
    Label,
    Outline,
    Span,
} from "./styles";

const Header = ({ t }: any) => {
    const location = useLocation();
    const history = useHistory()
    const [visible, setVisibility] = useState(false);

    const showDrawer = () => {
        setVisibility(!visible);
    };

    const onClose = () => {
        setVisibility(!visible);
    };

    const InfoMenuItem = () => {
        const scrollTo = (id: string) => {
            const element = document.getElementById(id) as HTMLDivElement;
            element.scrollIntoView({
                behavior: "smooth",
            });
            setVisibility(false);
        };
        return (
            <>
                <CustomNavLinkSmall onClick={() => scrollTo("intro")}>
                    <Span>Home</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo("Features")}>
                    <Span>Features</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo("Benefits")}>
                    <Span>Benefits</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall onClick={() => scrollTo("integration")}>
                    <Span>Integration</Span>
                </CustomNavLinkSmall>
                {/* <CustomNavLinkSmall onClick={() => scrollTo("testimonials")}>
                    <Span>Testimonials</Span>
                </CustomNavLinkSmall> */}
                <CustomNavLinkSmall onClick={() => scrollTo("faq")}>
                    <Span>FAQ</Span>
                </CustomNavLinkSmall>
                <CustomNavLinkSmall
                    style={{ width: "180px" }}
                    onClick={() => scrollTo("contact")}
                >
                    <Span>
                        <Button>{t("Contact")}</Button>
                    </Span>
                </CustomNavLinkSmall>
            </>
        );
    };

    const AppMenuItem = () => {
        const navigateTo = (path: string) => {
            history.push(path);
            setVisibility(false);
        };
        return (
            <>
                <CustomNavLinkSmall onClick={() => navigateTo('/info')}>
                    {/* <Span>Info</Span> */}
                </CustomNavLinkSmall>
            </>
        );
    };



    return (
        <HeaderSection>
            <Container textColor="#000">
                <Row justify='space-between'>
                    <LogoContainer to='/' aria-label='homepage'>
                        <div className="Header-logo">
                            <SvgIcon
                                src="logo.svg"
                            />
                        </div>

                        <div>
                            <p style={{
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: 'var(--text-color)',
                                margin: 0,
                            }}>CompliancePass</p>
                        </div>
                    </LogoContainer>
                    <NotHidden>
                        <Switch>
                            <Route
                                path="/info"
                                component={InfoMenuItem}
                            />
                            <Route
                                path="/"
                                exact={false}
                                component={AppMenuItem}
                            />
                        </Switch>
                    </NotHidden>
                    {
                        location.pathname === '/info'
                            ? (
                                <Burger onClick={showDrawer}>
                                    <Outline />
                                </Burger>
                            ) : null
                    }

                </Row>
                <Drawer closable={false} open={visible} onClose={onClose} style={{
                    backgroundColor: "#3651FE",
                }}>
                    <Col style={{ marginBottom: "2.5rem" }}>
                        <Label onClick={onClose}>
                            <Col span={12}>
                                <Menu>Menu</Menu>
                            </Col>
                            <Col span={12}>
                                <Outline />
                            </Col>
                        </Label>
                    </Col>
                    <Switch>
                        <Route
                            path="/info"
                            component={InfoMenuItem}
                        />
                        <Route
                            path="/"
                            exact={false}
                            component={AppMenuItem}
                        />
                    </Switch>
                </Drawer>
            </Container>
        </HeaderSection>
    );
};

export default withTranslation()(Header);
