import { Row, Col, Button } from "antd";
import { withTranslation } from "react-i18next";

import Container from "../../common/Container";
import './style.css'
import {
    FooterSection,
    Title,
    NavLink,
    Extra,
    LogoContainer,
    Para,
    Large,
    Empty,
    FooterContainer,
    Language,
    Label,
    LanguageSwitch,
    LanguageSwitchContainer,
    LinkText,
    CustomText,
    ForCarrier,
    LargeDiv,
    FooterLogo,
} from "./styles";
import { SvgIcon } from "../../common/SvgIcon";
import { config } from "../../config";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import { useState } from "react";
import { CustomNavLinkSmall, Span } from "../Header/styles";

interface SocialLinkProps {
    href: string;
    src: string;
}

const Footer = ({ t }: any) => {
    const history = useHistory();
    const [visible, setVisibility] = useState(false);

    const scrollTo = (id: string) => {
        const element = document.getElementById(id) as HTMLDivElement;
        element.scrollIntoView({
            behavior: "smooth",
        });
    };
    const SocialLink = ({ href, src }: SocialLinkProps) => {
        return (
            <a
                href={href}
                target='_blank'
                rel='noopener noreferrer'
                key={src}
                aria-label={src}
            >
                <SvgIcon src={src} width='25px' height='25px' />
            </a>
        );
    };
    const AppMenuItem = () => {
        const navigateTo = (path: string) => {
            history.push(path);
            setVisibility(false);
        };
        return (
            <>
                <CustomNavLinkSmall onClick={() => navigateTo("/info")}>
                    <ForCarrier>For carriers</ForCarrier>
                </CustomNavLinkSmall>
            </>
        );
    };
    const InfoMenuItem = () => {
        return (
            <div
                className="footer_Menu"
            >
                <LargeDiv
                    onClick={() => scrollTo("intro")}
                >
                    <Large>Home</Large>
                </LargeDiv>
                <LargeDiv
                    onClick={() => scrollTo("Benefits")}
                   
                >
                    <Large>Benefits</Large>
                </LargeDiv>
                <LargeDiv
                    onClick={() => scrollTo("Features")}
                   
                >
                    <Large>Features</Large>
                </LargeDiv>
                <LargeDiv
                    onClick={() => scrollTo("integration")}
                   
                >
                    <Large>Integration</Large>
                </LargeDiv>
                {/* <div
                    onClick={() => scrollTo("testimonials")}
                    style={{
                        marginLeft: 10,
                    }}
                >
                    <Large>Testimonials</Large>
                </div> */}
                <LargeDiv
                    onClick={() => scrollTo("faq")}
                   
                >
                    <Large>FAQ</Large>
                </LargeDiv>
            </div>
        );
    };
    return (
        <>
            <FooterSection>
                <Container>
                    <Row justify='space-between' align='middle' >
                        <Col xs={24} sm={24} md={12}>
                            <Row align='middle' className="footer_logo" gutter={[8, 16]}>
                              
                                <Col>
                                <Link to={'/'} >
                                <SvgIcon
                                        src='logoplane.svg'
                                        aria-label='homepage'
                                        width='50px'
                                        height='64px'
                                    />
                                </Link>
                                    
                                </Col>
                                <Col>
                                <Link to={'/'} >
                                    <Label>CompliancePass</Label>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={12} offset={0}>
                            <Switch>
                                <Route path='/info' component={InfoMenuItem} />
                                <Route
                                    path='/'
                                    exact={false}
                                    component={AppMenuItem}
                                />
                            </Switch>
                        </Col>
                    </Row>
                    <Row justify='space-between' align='middle' style={{ marginTop: 10 }}>
                        <Col>
                            <Row justify='space-between' align='middle'>
                                <Col>
                                    <SocialLink
                                        href={config.FACEBOOK}
                                        src='facebook.svg'
                                    />
                                </Col>
                                <Col style={{
                                    marginLeft: 10
                                }}>
                                    <SocialLink
                                        href={config.INSTAGRAM}
                                        src='instagram.svg'
                                    />
                                </Col>
                                <Col style={{
                                    marginLeft: 10
                                }}>
                                    <a href={`mailto:${config.EMAIL}`}>
                                        <p
                                            style={{
                                                color: "#000",
                                                fontSize: 12,
                                                // marginTop: 5
                                                margin: 0
                                            }}
                                        >
                                            {config.EMAIL}
                                        </p>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="footer_policy" gutter={[8, 16]} align='middle'>
                                <Col>
                                    <a href={config.DATENCHUTZ} className="footer_policy_text">
                                        <LinkText>Privacy Policy</LinkText>
                                    </a>
                                </Col>
                                <Col>
                                    <a href={config.TERMS_CONDINTION} className="footer_policy_text1">
                                        <LinkText>Terms of Service</LinkText>
                                    </a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </FooterSection>

        </>
    );
};

export default withTranslation()(Footer);

