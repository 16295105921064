export const config = {
  HTTP_SERVER:
    process.env.REACT_APP_HTTP_SERVER ||
    "https://fasttrack-pax-web-backend.vitim.io",
  RECAPTCHA: {
    SITE_KEY: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  },
  COMPLIANCEPASS_APP_STORE:
    process.env.REACT_APP_COMPLIANCEPASS_APP_STORE ||
    "https://apps.apple.com/ru/app/compliancepass/id6463605986?l=en-GB",
  COMPLIANCEPASS_PLAY_STORE:
    process.env.REACT_APP_COMPLIANCEPASS_PLAY_STORE ||
    "https://play.google.com/store/apps/details?id=io.blueicareus.dti",
  DEMO_LINK:
    process.env.REACT_APP_DEMO_LINK ||
    "https://fasttrack-booking-demo.vitim.io",
  DATENCHUTZ:
    process.env.REACT_APP_DATENCHUTZ ||
    "https://www.validationcheckport.com/datenschutzprivacy",
  TERMS_CONDINTION:
    process.env.REACT_APP_TERMS_CONDINION ||
    "https://www.validationcheckport.com/termsconditions",
  INSTAGRAM:
    process.env.REACT_APP_INSTAGRAM || "https://www.instagram.com/blueicareus/",
  FACEBOOK:
    process.env.REACT_APP_FACEBOOK || "https://www.facebook.com/blueicareus/",
  EMAIL: process.env.REACT_APP_EMAIL || "info@cyberfish.ch",
  DEMO_AIRLINE: process.env.REACT_APP_DEMO_AIRLINE || "999",
  SHOW_WEB_LINK: process.env.REACT_APP_WEB_LINK === "available",
};
