import { lazy, Suspense, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {

  const location = useLocation();

  useEffect(() => {
    const body = document.getElementsByTagName('body');
    if (location.pathname === '/info') {
      body[0].style.background = "#FFF"
      body[0].className = 'airline';
    } else {
      body[0].style.background = "#3651FE"
      body[0].className = 'passenger';
    }
  }, [location]);

  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
