import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterSection = styled("footer")`
  background: rgb(241, 242, 243);
  padding: 2.5rem 0;
`;

export const Title = styled("h4")`
  font-size: 20px;
  text-transform: capitalize;
  color: #3651fe;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #3651fe;
  }
`;

export const Extra = styled("section")`
  background: rgb(241, 242, 243);
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 2rem;
`;

export const LogoContainer = styled("div")`
  display: flex;
  position: relative;
`;

export const Para = styled("div")`
  color: #3651fe;
  font-size: 14px;
  width: 70%;
`;

export const Large = styled("span")<any>`
  font-size: 16px;
  color: #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 24px;
  display: block;
  transition: all 0.3s ease-in-out;
  max-width: max-content;

  &:hover {
    color: #3651fe;
    text-underline-position: under;
    text-decoration: #3651fe wavy underline;
  }
`;
export const LinkText = styled("p")<any>`
  font-size: 16px;
  color: #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 14px;
  display: block;
  // margin-bottom: 0.625rem;
  transition: all 0.3s ease-in-out;
  max-width: max-content;
  text-align: left;
  width: 100%;

  &:hover {
    color: #3651fe;
    text-underline-position: under;
    text-decoration: #3651fe wavy underline;
  }
  //  @media screen and (max-width: 769px) {
  //   font-size: 12px;
  //  }
`;
export const FooterLogo = styled(Link)`
  
`;
export const LargeDiv = styled("div")`
  margin-left: 10px;
  &:first-child {
    margin-left: 0px;
  }
  @media screen and (max-width: 390px) {
    margin-left: 5px;
  }
`;

export const ForCarrier = styled("p")<any>`
  font-size: 20px;
  color: #18216d;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 14px;
  display: block;
  // margin-bottom: 0.625rem;
  transition: all 0.3s ease-in-out;
  max-width: max-content;
  font-weight: 700;
  text-align: center;

  &:hover {
    color: #3651fe;
    text-underline-position: under;
    text-decoration: #3651fe wavy underline;
  }
`;

export const CustomText = styled("p")<any>`
  font-size: 14px;
  color: #000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  line-height: 14px;
  display: block;
  margin-bottom: 0.625rem;
  transition: all 0.3s ease-in-out;
  max-width: max-content;
`;

export const Empty = styled("div")`
  position: relative;
  height: 53px;
`;

export const FooterContainer = styled("div")`
  max-width: 510px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  transition: all 0.1s ease-in-out;

  a {
    &:hover,
    &:active,
    &:focus {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  @media screen and (max-width: 769px) {
    width: auto;

    a:not(:last-child) {
      display: none;
    }
  }

  div {
    cursor: pointer;
    margin-right: 15px;
    width: 25px;
    height: 25px;

    &:hover {
      fill: rgb(255, 130, 92);
    }
  }
`;

export const Language = styled("h4")`
  font-size: 20px;
  text-transform: capitalize;
  color: #18216d;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const Label = styled("label")`
  font-size: 22px;
  text-transform: capitalize;
  color: #18216d;
  display: block;
  font-family: "Motiva Sans Bold", serif;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: capitalize;
  line-height: 14px;
  // margin-bottom: 0.625rem;
  transition: all 0.3s ease-in-out;
  max-width: max-content;
  font-weight: 700;
  text-align: center;

  &:hover {
    color: #3651fe;
    text-underline-position: under;
    text-decoration: #3651fe wavy underline;
  }
  //   @media screen and (max-width: 950px){
  //     font-size: 20px;

  //   }
  //     @media screen and (max-width: 890px){
  //     font-size: 18px;

  //   }
  //    @media screen and (max-width: 830px){
  //     font-size: 16px;
  //     margin-top: 5px;
  //   }
  //  @media screen and (max-width: 770px) {
  //   font-size: 14px;
  //  }
  //   @media screen and (max-width: 415px) {
  //     /* padding: 1.5rem 0; */
  //     display: none;

  //   }
`;

export const LanguageSwitch = styled("div")<any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;
